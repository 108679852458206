import React, { Component } from "react"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import aboutImg from "../images/default.jpg"
import Link from "gatsby"
import SidebarComponent from "../components/SidebarComponent"

export class Report extends Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title

    return (
      <div>
        <Helmet title={siteTitle} />
        <Layout>
          <section className="about-us pad-70">
            <div className="container">
              <div className="row">
                <div className="col-lg-4">
                  <SidebarComponent />
                </div>

                <div className="col-lg-8">Report</div>
              </div>
            </div>
          </section>
        </Layout>
      </div>
    )
  }
}

export default Report

export const pageQuery = graphql`
  query AboutReportQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`

// ブログの内容と研究報告を分ける
// ECとLPのプロダクトを分ける（ECに直接飛ばさない）
